<template>
  <v-list-item dense :disabled="disabled" v-bind="$attrs" v-on="$listeners">
    <!-- icon -->
    <v-list-item-icon
      :class="getMargin"
      v-show="hasSlot('icon') || hasSlot('iconName')"
    >
      <slot name="icon">
        <v-icon :color="getIconColor" :disabled="disabled">
          <slot name="iconName"></slot>
        </v-icon>
      </slot>
    </v-list-item-icon>

    <!-- content with tooltip -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <!-- content -->
        <v-list-item-content v-on="on">
          <!-- title -->
          <v-list-item-title>
            <slot name="default"></slot>
          </v-list-item-title>

          <!-- subtitle -->
          <v-list-item-subtitle v-show="hasSlot('subtitle')">
            <slot name="subtitle"></slot>
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
      <slot name="tooltipContent"></slot>
    </v-tooltip>

    <!-- action -->
    <v-list-item-action v-show="hasSlot('action')">
      <slot name="action"></slot>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { menuItemSettingsMixin } from "@/mixins/shared/base/settings/menuItemSettingsMixin";
import { slottableMixin } from "@/mixins/shared/slottable/slottableMixin";
import { direction, getMarginClass } from "@/design/spacing/Spacing";
import { colorRecordDeletedClass } from "@/model/record/recordModel";

export default {
  name: "BaseMenuItem",
  inheritAttrs: false,
  mixins: [menuItemSettingsMixin, slottableMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    marginSize: {
      type: String,
      default: undefined
    },
    isIconDanger: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getMargin() {
      return this.marginSize
        ? getMarginClass(direction.rightLeft, this.marginSize)
        : "";
    },
    /**
     * Get Icon Color
     * @return {string|*}
     */
    getIconColor() {
      return this.isIconDanger
        ? colorRecordDeletedClass
        : this.menuItemColorClass;
    }
  }
};
</script>
