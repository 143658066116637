export const slottableMixin = {
  methods: {
    /**
     * has Slot
     * @param {string} name
     * @return {boolean}
     */
    hasSlot(name) {
      return !!this.$slots[name];
    }
  }
};
